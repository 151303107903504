import { type LoaderFunctionArgs, redirect } from "@remix-run/node";
import { Link } from "@remix-run/react";
import { Text } from "@ui/components/Typography/Text";
import { Button } from "@ui/components/general/button";
import { Logo } from "@ui/components/original/Logo";
import { SlackIcon } from "@ui/components/original/svgIcons/SlackIcon";
import { currentUserMiddleware } from "~/middlewares/currentUserMiddleware";

export async function loader({ request, ...args }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const error = url.searchParams.get("error");

  const { isServerLogin } = await currentUserMiddleware({ request, ...args });
  if (isServerLogin && !error) {
    return redirect("/");
  }

  return null;
}

function LoginPage() {
  return (
    <div className="container">
      <header className="py-2">
        <Logo />
      </header>
      <div className="mx-auto max-w-md space-y-8">
        <Text as="h1" type="title" className="text-center">
          HubDesk にログイン
        </Text>
        <div className="space-y-4">
          <Button asChild>
            <Link to="/auth/slack/login" className="w-full">
              <SlackIcon className="h-4 w-4" />
              Slack でログイン
            </Link>
          </Button>
          {/* <Text as="p" type="body" className="text-muted-foreground">
            アカウントを持っていない場合、
            <a
              className="text-primary"
              href="https://noraneers.slack.com/apps/A06CEBR2KGR-hub-desk?tab=more_info"
            >
              作成する
            </a>
          </Text> */}
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
